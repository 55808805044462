import defaultTheme from 'tailwindcss/defaultTheme';
import forms from '@tailwindcss/forms';

/** @type {import('tailwindcss').Config} */
export default {
    content: [
        './vendor/laravel/framework/src/Illuminate/Pagination/resources/views/*.blade.php',
        './storage/framework/views/*.php',
        './resources/views/**/*.blade.php',
        './resources/js/**/*.vue',
    ],
    theme: {
        extend: {
            fontFamily: {
                sans: ['Inter', ...defaultTheme.fontFamily.sans],
            },
            fontSize: {
                xxs: ['0.625rem', '0.875rem'],
            },
            colors: {
                primary: {
                    500: 'var(--color-primary-500)',
                    400: 'var(--color-primary-400)',
                    300: 'var(--color-primary-300)',
                    200: 'var(--color-primary-200)',
                },
                gray: {
                    600: 'var(--color-gray-600)',
                    500: 'var(--color-gray-500)',
                    400: 'var(--color-gray-400)',
                    300: 'var(--color-gray-300)',
                    200: 'var(--color-gray-200)',
                    100: 'var(--color-gray-100)',
                },
                success: {
                    500: 'var(--color-success-500)',
                    400: 'var(--color-success-400)',
                    300: 'var(--color-success-300)',
                },
                warning: {
                    400: 'var(--color-warning-400)',
                    300: 'var(--color-warning-300)',
                },
                danger: {
                    400: 'var(--color-danger-400)',
                    300: 'var(--color-danger-300)',
                },
            },
            // backgroundImage: {
            //     'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))', // Zorg dat deze bestaat
            // },
            // gradientColorStops: theme => ({
            //     ...theme('colors'),
            // }),
            boxShadow: {
                section: '0 14px 34px 0 rgba(0, 0, 0, .06), 0 2px 3px 0 rgba(0, 0, 0, .12)'
            }
        },
    },
    safelist: [
        'swiper-pagination',
    ],
    plugins: [forms],
};
